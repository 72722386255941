@use '@angular/material' as mat;
@use "../base/colors" as *;
@use "../base/variables" as *;
@use "../base/typography" as *;
@use "light-theme" as *;
@use "sass:color";
@use "sass:map";

$mat-primary: (
  main: #3c3c3c,
  lighter: #c5c5c5,
  darker: #272727,
  200: #3c3c3c, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-primary: mat.m2-define-palette($mat-primary, main, lighter, darker);



$mat-accent: (
  main: #3aaa35,
  lighter: #c4e6c2,
  darker: #259022,
  200: #3aaa35, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);

$theme-accent: mat.m2-define-palette($mat-accent, main, lighter, darker);


body {
  --warn-color: #d04145;
  --warn-lighter-color: #f1c6c7;
  --warn-darker-color: #be2a2e;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}

$mat-warn: (
  main: #d04145,
  lighter: #f1c6c7,
  darker: #be2a2e,
  200: #d04145, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-warn: mat.m2-define-palette($mat-warn, main, lighter, darker);
;

$theme: (
  color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn,
    foreground: $mat-light-theme-foreground,
    background: $mat-light-theme-background,
  ),
  typography: $fontConfig,
  density: 0,
);

@include mat.all-component-themes($theme);

$dense-theme: (
  density: -3,
);

.dense-1 {
  @include mat.all-component-densities($dense-theme)
}

.mat-mdc-fab, .mat-mdc-mini-fab {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 00), 0 0 0 0 rgba(0, 0, 0, 00), 0 0 0 0 rgba(0, 0, 0, 0);
  border-radius: 5px !important;
}

.toolbar-action-fab {
  background-color: unset !important;
  color: black !important;
  border: map.get($app-primary, default) 1px solid !important;
  box-shadow: unset !important;
  border-radius: 5px !important;
}

.toolbar-action-fab:hover {
  background-color: map.get($app-accent, default) !important;
}
