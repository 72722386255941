// Import material theming functions
@use '@angular/material' as mat;
@use 'sass:color';
@use "sass:map";


// Copy the palettes from your selected theme (usually theme.scss).
$app-primary: mat.m2-define-palette(mat.$m2-gray-palette, 800, 700, 900);
$app-accent: mat.m2-define-palette(mat.$m2-green-palette);
$app-warn: mat.m2-define-palette(mat.$m2-pink-palette);
$dark-primary: mat.m2-define-palette(mat.$m2-gray-palette, 800, 700, 900);
$dark-accent: mat.m2-define-palette(mat.$m2-green-palette, 800);
$dark-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create your Sass color vars (will be available in all the project)
$primary: mat.m2-get-color-from-palette($app-primary);
$primary-lighter: mat.m2-get-color-from-palette($app-primary, lighter);
$primary-contrast: mat.m2-get-color-from-palette($app-primary, lighter-contrast);
$accent: mat.m2-get-color-from-palette($app-accent);
$accent-lighter: mat.m2-get-color-from-palette($app-accent, lighter);
$accent-contrast: mat.m2-get-color-from-palette($app-accent, lighter-contrast);
$warn: mat.m2-get-color-from-palette($app-warn);
$warn-lighter: mat.m2-get-color-from-palette($app-warn, lighter);
$warn-contrast: mat.m2-get-color-from-palette($app-warn, lighter-contrast);
// Light bg
$light-background: #fafafa;
$light-bg-darker-5: color.scale($light-background, $lightness: -5%);
$light-bg-darker-10: color.scale($light-background, $lightness: -10%);
$light-bg-darker-20: color.scale($light-background, $lightness: -20%);
$light-bg-darker-30: color.scale($light-background, $lightness: -30%);
$light-bg-lighter-5: color.scale($light-background, $lightness: 5%);
$dark-bg-tooltip: color.scale(#2c2c2c, $lightness: 20%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

$dense-theme: (
  density: -3,
);

.dense-1 {
  @include mat.all-component-densities($dense-theme)
}
