@use "sass:color";
@use '@angular/material' as mat;
@use '../../../../node_modules/tailwindcss/base.css';
@use '../../../../node_modules/tailwindcss/components.css';
@use '../../../../node_modules/tailwindcss/utilities.css';


// Theming
@use "styles/theme/default-theme" as *;

@use "styles/base/colors" as *;
@use "styles/base/variables" as *;

// Compute font config
@include mat.elevation-classes();
@include mat.app-background();

body {
  --primary-color: #3c3c3c;
  --primary-lighter-color: #c5c5c5;
  --primary-darker-color: #272727;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};

  font-family: Roboto, "Helvetica Neue", sans-serif;
  --accent-color: #3aaa35;
  --accent-lighter-color: #c4e6c2;
  --accent-darker-color: #259022;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};

  --warn-color: #d04145;
  --warn-lighter-color: #f1c6c7;
  --warn-darker-color: #be2a2e;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}

// Theme Init

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button, .mat-stroked-button, .mat-flat-button {
  padding: 0 1.15em;
  margin: 0 .65em;
  min-width: 3em;
  line-height: 36.4px
}

.mat-standard-chip {
  padding: .5em .85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons', serif;
}

html, body {
  padding: 0;
  margin: 0;
}

.custom-select-field-background {

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: white;
  }
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-right-style: hidden;
}

.primary {
  background-color: mat.m2-get-color-from-palette($theme-primary);
}

.highlight {
  background-color: mat.m2-get-color-from-palette($theme-accent);
  
  &.mat-mdc-tab-link {
    background-color: #2E7D32 !important;
    .mat-icon {
      color: white !important;
      fill: white !important;
    }

    .mdc-tab__text-label {
     color: white !important;
    }

    .mdc-tab-indicator__content--underline {
        border: none;
      }
  }
}

.main-menu-item:hover {
  border-color: mat.m2-get-color-from-palette($theme-accent);
  border-width: 1px;
}

.row-highlight {
  &:hover {
    background-color: $light-bg-darker-10;
  }
}

.border-bottom-accent {
  border-bottom: 3px solid mat.m2-get-color-from-palette($theme-accent);
}

.background-primary {
  background-color: mat.m2-get-color-from-palette($theme-primary) !important;
  color: mat.m2-get-color-from-palette($theme-primary, default-contrast) !important;
}

.background-accent {
  background-color: mat.m2-get-color-from-palette($theme-accent) !important;
  color: mat.m2-get-color-from-palette($theme-accent, default-contrast) !important;
}

.main-header-action {
  border-color: mat.m2-get-color-from-palette($theme-primary, lighter) !important;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.25rem;
  padding: 0.1rem 0.25rem;
  min-width: 72px;
}

.main-header-action:hover {
  border-color: mat.m2-get-color-from-palette($theme-primary) !important;
  background-color: mat.m2-get-color-from-palette($theme-primary, lighter) !important;
}

.main-menu-action.highlight {
  background-color: mat.m2-get-color-from-palette($theme-accent) !important;
}

.main-menu-action:not(.highlight):hover {
  @apply bg-stone-100;
}

.main-menu-action.highlight:hover {
  background-color: mat.m2-get-color-from-palette($theme-accent, darker) !important;
}

mat-sidenav[style] {
  visibility: visible !important;
}

.mat-drawer {
  transform: none !important;
}

.mat-mdc-form-field.filter-option-selected .mat-mdc-text-field-wrapper {
  background-color: mat.m2-get-color-from-palette($theme-primary);

  input, button {
    color: white;
  }

  mat-label {
    display: none;
  }
}

.app-link {
  text-decoration: none;
  color: black;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.mat-mdc-header-row {
  --tw-bg-opacity: 1;
  background-color: var(--primary-color) !important;
  color: white !important;
}

th mat-slide-toggle label {
  color: white !important;
}

.custom-timeline-theme {
    --ngx-timeline-icon: var(--accent-color);
    --ngx-timeline-period-inner-container: var(--accent-color);
    --ngx-timeline-line-background: var(--accent-color);
}